import React from 'react';
import { Caveat, CaveatSeverity } from '@cimpress-technology/caveats/dist/types';
import { Accordion, colors, Label, Table } from '@cimpress/react-components';
import { css } from '@emotion/css';
import orderBy from 'lodash/orderBy';

const tableCss = css`
  .robot {
    display: none;
  }
  .pagination {
    margin-top: 4px;
  }
  .table {
    margin-bottom: 6px;
  }
  .table th {
    padding-top: 8px;
    padding-bottom: 8px;
  }
  .table td {
    padding-top: 8px;
    padding-bottom: 8px;
  }
`;

const errorCodeCss = (color: string) => css`
  color: ${color};
  font-weight: bold;
  cursor: pointer;
  :visited {
    color: ${color};
  }
`;

const caveatCodeColors = new Map<CaveatSeverity, string>([
  [CaveatSeverity.Transient, colors.info.base],
  [CaveatSeverity.Warning, colors.warning.base],
  [CaveatSeverity.HardStop, colors.danger.base],
]);

const severityLabel = (severity: CaveatSeverity) => {
  switch (severity) {
    case CaveatSeverity.Transient: {
      return <Label text="Transient" status="info" />;
    }
    case CaveatSeverity.Warning: {
      return <Label text="Warning" status="warning" />;
    }
    case CaveatSeverity.HardStop: {
      return <Label text="HardStop" status="danger" />;
    }
    default: {
      return <Label text="Unknown" style={{ color: colors.silver }} />;
    }
  }
};

const errorCodeText = (caveat: Caveat) => {
  const color = caveatCodeColors.get(caveat.severity) ?? colors.shale;
  return (
    <a
      className={errorCodeCss(color)}
      target="_blank"
      rel="noreferrer"
      href={`https://cimpress-support.atlassian.net/wiki/spaces/CI/pages/442335235/Caveats#${caveat.code}`}
    >
      {caveat.code}
    </a>
  );
};

const makeAccordion = ({ caveat }: { caveat: any }) => {
  const caveatDetails = { ...caveat };

  // Caveats aren't consistent with what fields they may have so we'll have to eliminate 
  // the known unwanted ones instead of picking ones that we want
  ['code', 'message', 'productConfigurationUrl', 'fulfillmentLocationId', 'severity'].forEach(
    key => delete caveatDetails[key],
  );
  return (
    <Accordion
      title="Details"
      style={{ border: 'none', marginBottom: '0px' }}
      bodyStyle={{ padding: '0px' }}
      headerStyle={{ padding: '12px 12px 12px 0', background: 'none' }}
    >
      <pre style={{ fontSize: 'inherit', whiteSpace: 'pre-wrap' }}>
        {JSON.stringify(caveatDetails, null, 4).replace(/"/g, '')}
      </pre>
    </Accordion>
  );
};

export const CaveatTable = ({ caveats }: { caveats: Caveat[] }) => {
  const columns = [
    {
      Header: 'Severity',
      accessor: 'severity',
      Cell: ({ original: row }: { original: Caveat }) => severityLabel(row.severity),
    },
    { Header: 'Error Code', accessor: 'code', Cell: ({ original: row }: { original: Caveat }) => errorCodeText(row) },
    {
      Header: 'Message',
      accessor: 'message',
      Cell: ({ original: row }: { original: Caveat }) => (
        <div>
          <div>{row.message}</div>
          {makeAccordion({ caveat: row })}
        </div>
      ),
    },
  ];

  const sortedCaveats = orderBy(caveats, 'severity');

  return (
    <div className={tableCss}>
      <Table columns={columns} data={sortedCaveats} isBordered={true} pageSize={3} paginationPosition="bottomRight" />
    </div>
  );
};
