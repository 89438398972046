import React from 'react';
import PropTypes from 'prop-types';

import IconShoppingCartFull from '@cimpress-technology/react-streamline-icons/lib/IconShoppingCartFull';
import IconShirtPlain from '@cimpress-technology/react-streamline-icons/lib/IconShirtPlain';
import IconDesignToolPenStation from '@cimpress-technology/react-streamline-icons/lib/IconDesignToolPenStation';
import IconShipmentTruck from '@cimpress-technology/react-streamline-icons/lib/IconShipmentTruck';
import IconShop from '@cimpress-technology/react-streamline-icons/lib/IconShop';
import IconAnalyticsPieAlt from '@cimpress-technology/react-streamline-icons/lib/IconAnalyticsPieAlt';
import IconUserSetting from '@cimpress-technology/react-streamline-icons/lib/IconUserSetting';
import IconFolderCode from '@cimpress-technology/react-streamline-icons/lib/IconFolderCode';
import IconBookOpen from '@cimpress-technology/react-streamline-icons/lib/IconBookOpen';
import IconMonitorShoppingCart from '@cimpress-technology/react-streamline-icons/lib/IconMonitorShoppingCart';
import IconRatingStar from '@cimpress-technology/react-streamline-icons/lib/IconRatingStar';
import IconArrowUpAlt from '@cimpress-technology/react-streamline-icons/lib/IconArrowUpAlt';
import IconArrowDownAlt from '@cimpress-technology/react-streamline-icons/lib/IconArrowDownAlt';
import IconUserFrame from '@cimpress-technology/react-streamline-icons/lib/IconUserFrame';
import { cx } from '@emotion/css';

const icons = {
  IconShoppingCartFull,
  IconShirtPlain,
  IconDesignToolPenStation,
  IconShipmentTruck,
  IconShop,
  IconAnalyticsPieAlt,
  IconUserSetting,
  IconFolderCode,
  IconBookOpen,
  IconMonitorShoppingCart,
  IconRatingStar,
  IconUserFrame,
};

const NavItem = ({ item, active, nested, onClick }) => {
  const IconComponent = item.iconComponent ? icons[item.iconComponent] : undefined;
  return (
    <a
      onClick={onClick}
      href={item.url}
      className={cx('list-group-item clickable', { active: active, 'level-two': nested })}>
      {IconComponent ? (
        <IconComponent style={{ marginRight: '21px' }} />
      ) : item.iconUrl ? (
        <img src={item.iconUrl} />
      ) : null}
      {item.text}
      {item.subItems &&
        (active ? (
          <IconArrowUpAlt style={{ marginRight: 0, marginLeft: 'auto' }} />
        ) : (
          <IconArrowDownAlt style={{ marginRight: 0, marginLeft: 'auto' }} />
        ))}
    </a>
  );
};

NavItem.propTypes = {
  item: PropTypes.object,
  active: PropTypes.bool,
  nested: PropTypes.bool,
  onClick: PropTypes.func,
};

export default NavItem;
