import React from 'react';
import cvar from '../theme/cvar';

export interface RadioIconProps {
  /** Width and height of the shape, defaults to '16px'. */
  size?: string;
  /** Color of the shape outline. */
  color?: string;
  /** Color of the area inside the outline. Defaults to 'none' (transparent) */
  backgroundColor?: string;
  /** Whether the radio button is selected or not */
  selected?: boolean;
  /** Custom class applied to the base element */
  className?: string;
}

export const RadioIcon = (props: RadioIconProps) => {
  const {
    size = '16px',
    color = cvar('color-button-primary'),
    backgroundColor = 'none',
    selected = false,
    className = '',
    ...rest
  } = props;
  return (
    <svg width={size} height={size} viewBox="0 0 24 24" className={className} {...rest}>
      <circle cx="12" cy="12" r="10" stroke={color} strokeWidth="2" fill={backgroundColor} />
      {selected && <circle cx="12" cy="12" r="5" fill={color} />}
    </svg>
  );
};
