const HEADER_CONFIG_URL = 'https://static.ux.cimpress.io/mcp-header/v3/';
import mcpapps from '../config/mcp-header/v3/mcpapps.json';
import menuitems from '../config/mcp-header/v3/menuitems.json';
import sidenav from '../config/mcp-header/v3/sidenav.json';

const getConfigFile = filename => {
  try {
    return fetch(`${HEADER_CONFIG_URL}${filename}`, {
      method: 'GET',
      mode: 'cors',
      headers: new Headers({
        'Content-Type': 'application/json',
      }),
    }).then(response => {
      if (response.status === 200) {
        return response.json();
      } else {
        throw new Error('Non 200 response from static site.');
      }
    });
  } catch (ex) {
    return Promise.reject(ex);
  }
};

const handleFailure = (error, fallback) => {
  console.error('Error retrieving config file from static site.  using fallback.', error);
  return fallback;
};

export const getMenuItems = () => {
  return getConfigFile('menuitems.json').catch(err => handleFailure(err, menuitems));
};

export const getMcpApps = () => {
  return getConfigFile('mcpapps.json').catch(err => handleFailure(err, mcpapps));
};

export const getSideNav = () => {
  return getConfigFile('sidenav.json').catch(err => handleFailure(err, sidenav));
};
