import React from 'react';
import PropTypes from 'prop-types';
import {
  STEPUP_AUTHENTICATION_REMINDER_KEY,
  STEPUP_AUTHENTICATION_EXPIRY_NOTIFICATION_KEY,
  REMINDER_BEFORE_MIN,
} from './StepupAuthConfig';
import { StepUpNotificationPopup } from './StepUpNotificationPopup';

export const StepupAuthReminderPopup = props => {
  const { remainingTime, openStepUpAuthModal } = props;

  return (
    <>
      <StepUpNotificationPopup
        title={`Your step up session is about to expire in another ${REMINDER_BEFORE_MIN} mins, if you want to
        continue with the step up session just click on continue.`}
        remainingTime={remainingTime}
        reminderBeforeMin={REMINDER_BEFORE_MIN}
        localStorageKey={STEPUP_AUTHENTICATION_REMINDER_KEY}
        openStepUpAuthModal={openStepUpAuthModal}
        delay={REMINDER_BEFORE_MIN * 60 * 1000}
      />
      <StepUpNotificationPopup
        title={`Your step up session is expired, if you want to
        continue with the step up session just click on continue.`}
        remainingTime={remainingTime}
        reminderBeforeMin={0}
        localStorageKey={STEPUP_AUTHENTICATION_EXPIRY_NOTIFICATION_KEY}
        openStepUpAuthModal={openStepUpAuthModal}
      />
    </>
  );
};

StepupAuthReminderPopup.propTypes = {
  remainingTime: PropTypes.number.isRequired,
  openStepUpAuthModal: PropTypes.func.isRequired,
};
