import { Card, colors, Copy, NavTab, NavTabItem } from '@cimpress/react-components';
import { css } from '@emotion/css';
import { Moment } from 'moment';
import React, { useState } from 'react';
import { useFulfillmentConfiguration } from '../hooks/useFulfillmentConfiguration';
import { useProduct } from '../hooks/useProduct';
import { useResource } from '../hooks/useResource';
import { InventoryResponse } from '../services/inventory';
import { DebugBranch, DebugBranchRequest } from '../services/itemDeliveryPossibilities';
import Loading from './Loading';
import { CaveatTable } from './CaveatTable';
import { FulfillmentConfigurationText } from './FulfillmentConfigurationText';
import InventoryAvailability from './InventoryAvailability';
import { NoCaveats } from './NoCaveats';
import { PriceText } from './PriceText';
import { ProductImage } from './ProductImage';
import { Timeline } from './Timeline';
import ViewLogisticsDiagnosticsButton from './ViewLogisticsDiagnosticsButton';
import getDeliverableDatesRequest from '../helpers/getDeliverableDatesRequest';
import { PackEvaluation, ShipDateResponse } from '../types';
import { LinksTable } from './LinksTable';

type DeliveryPossibilityCardProps = {
  accessToken: string;
  orderCreatedDate: Moment;
  branch: DebugBranch;
  timezone: string | undefined;
  className: string;
  request: DebugBranchRequest;
};

const navTabCss = css`
  margin-bottom: 20px;
`;

const productNameCss = css`
  display: flex;
  align-items: center;
`;

const productNameTextCss = css`
  max-width: 500px;
  white-space: nowrap;
  overflow: hidden;
  display: inline-block;
  text-overflow: ellipsis;
  font-size: 1.5rem;
  font-weight: bold;
  margin-right: 10px;
`;

const earliestDateTextCss = css`
  font-size: 2.5rem;
  font-weight: bold;
  margin-bottom: 5px;
`;

const undeliverableTextCss = css`
  font-size: 2.5rem;
  font-weight: bold;
  margin-bottom: 5px;
  color: ${colors.shale};
`;

const cardCss = css`
  width: 800px;
  min-height: 350px;
  margin: 0 10px 10px 10px;
  .crc-nav-tabs {
    margin-bottom: 20px;
  }
  overflow: auto;
`;

const productImageCss = css`
  margin-right: 10px;
`;

const deliveryScenarioCss = css`
  display: flex;
  margin-bottom: 10px;
`;

const inventoryAvailablityCss = css`
  display: flex;
  justify-content: right;
  margin-bottom: 5px;
`;

const deliveryScenarioGrowCss = css`
  flex-grow: 2;
`;

export const DeliveryPossibilityCard = ({
  accessToken,
  orderCreatedDate,
  branch,
  request,
  timezone,
}: DeliveryPossibilityCardProps) => {
  const OVERVIEW_TAB = 'OVERVIEW';
  const CAVEAT_TAB = 'CAVEAT';
  const LINKS_TAB = 'LINKS';

  const [selectedTab, setSelectedTab] = useState(OVERVIEW_TAB);

  const { data: product, isLoading: isLoadingProduct } = useProduct(
    accessToken,
    branch._links.productConfiguration?.href,
  );
  const { data: fulfillmentConfiguration, isLoading: isLoadingFulfillmentConfiguration } = useFulfillmentConfiguration(
    accessToken,
    branch._links.fulfillmentConfiguration?.href,
  );
  const { data: inventory, isLoading: isLoadingInventory } = useResource<InventoryResponse>(
    accessToken,
    branch._links.inventory?.href ? `${branch._links.inventory.href}&showDebug=true` : '',
  );
  const { data: packEvaluation, isLoading: isLoadingPackDetails } = useResource<PackEvaluation>(
    accessToken,
    branch._links.packEvaluation?.href,
  );
  const { data: shipDateResponse, isLoading: isLoadingShipDate } = useResource<ShipDateResponse>(
    accessToken,
    branch._links?.shipDate?.href,
  );

  const isLoading =
    isLoadingProduct ||
    isLoadingFulfillmentConfiguration ||
    isLoadingInventory ||
    isLoadingPackDetails ||
    isLoadingShipDate;

  if (isLoading) {
    return <Loading fillSpace={false} />;
  }

  const earliestDeliverableDate = branch.deliverableDates?.[0];
  const earliestDate = earliestDeliverableDate && earliestDeliverableDate.date;

  let deliverableDatesRequest;
  if (shipDateResponse && packEvaluation) {
    deliverableDatesRequest = getDeliverableDatesRequest({
      request,
      branch,
      packEvaluation,
      shipDateData: shipDateResponse,
    });
  }

  const header = (
    <div className={productNameCss}>
      <div className={productNameTextCss}>{product?.name}</div>
      <Copy variant="text" value={product?.mcpSku}>
        {product?.mcpSku}
      </Copy>
    </div>
  );

  const overviewTabContent = (
    <div>
      <div className={deliveryScenarioCss}>
        <div className={productImageCss}>
          <ProductImage product={product} />
        </div>
        <div className={deliveryScenarioGrowCss}>
          {earliestDate ? (
            <div className={earliestDateTextCss}>Earliest delivery by {earliestDate.format('MMMM Do YYYY')}</div>
          ) : (
            <div className={undeliverableTextCss}>Undeliverable</div>
          )}
          <PriceText price={earliestDeliverableDate?.price} />
          <FulfillmentConfigurationText fulfillmentConfiguration={fulfillmentConfiguration} />
          <div className={inventoryAvailablityCss}>
            <InventoryAvailability
              orderCreatedDate={orderCreatedDate}
              inventory={inventory}
              requestedQuantity={request.quantity}
            />
          </div>
        </div>
      </div>
      <Timeline
        accessToken={accessToken}
        orderCreatedDate={orderCreatedDate}
        earliestDeliveryDate={earliestDate}
        debugLinks={branch._links}
        timezone={timezone}
        shipDateData={shipDateResponse}
        requestedQuantity={request.quantity}
      />
      {deliverableDatesRequest && fulfillmentConfiguration?.usesCCMData && (
        <ViewLogisticsDiagnosticsButton deliverableDatesRequest={deliverableDatesRequest} />
      )}
    </div>
  );

  const caveatTabContent = (
    <div>{branch.caveats.length === 0 ? <NoCaveats /> : <CaveatTable caveats={branch.caveats} />}</div>
  );

  const linksTabContent = <LinksTable links={branch._links} />;

  const tabContentMap = {
    [OVERVIEW_TAB]: overviewTabContent,
    [CAVEAT_TAB]: caveatTabContent,
    [LINKS_TAB]: linksTabContent,
  };

  const tabContent = tabContentMap[selectedTab as keyof typeof tabContentMap];

  return (
    <Card className={cardCss} header={header}>
      <NavTab className={navTabCss}>
        <NavTabItem active={selectedTab === OVERVIEW_TAB}>
          <button onClick={() => setSelectedTab(OVERVIEW_TAB)}>Overview</button>
        </NavTabItem>
        <NavTabItem active={selectedTab === CAVEAT_TAB}>
          <button onClick={() => setSelectedTab(CAVEAT_TAB)}>{`Caveats (${branch.caveats.length})`}</button>
        </NavTabItem>
        <NavTabItem active={selectedTab === LINKS_TAB}>
          <button onClick={() => setSelectedTab(LINKS_TAB)}>Links</button>
        </NavTabItem>
      </NavTab>
      {tabContent}
    </Card>
  );
};
