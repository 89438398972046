import React, { JSXElementConstructor, ReactElement, ReactNode } from 'react';
import { cx, css } from '@emotion/css';
import cvar from './theme/cvar';
import { PublicComponentProps } from './types';
import * as colors from './colors';

type Size = 'default' | 'sm' | 'lg';

type Variant = 'default' | 'secondary' | 'primary' | 'link' | 'anchor';

type Color = 'primary' | 'danger';

export interface ButtonProps extends PublicComponentProps {
  /**
   * The ability to make the button fill the content block.
   */
  blockLevel?: boolean;

  /**
   * The inner contents of the button.
   */
  children?: ReactNode;

  /**
   * The ability to disable the button.
   */
  disabled?: boolean;

  /**
   * The ability to make the button navigate to a url.
   */
  href?: string;
  /**
   * Specify navigation behavior
   */
  target?: '_blank' | '_parent' | '_self' | '_top';

  /**
   * Icon to display as the button content.
   */
  icon?: ReactNode;

  /**
   * The callback function used when the onMouseEnter event occurs.
   */
  onMouseEnter?: (e: any) => void;

  /**
   * The callback function used when the onMouseLeave event occurs.
   */
  onMouseLeave?: (e: any) => void;

  /**
   * The callback function used when a button is clicked.
   */
  onClick?: (e: any) => void;

  /**
   * The size of the button you are creating.
   */
  size?: Size;

  /**
   * The variant of button you are creating.
   */
  variant?: Variant;

  color?: Color;
}

export interface ATagProps extends React.AnchorHTMLAttributes<HTMLAnchorElement> {
  children?: ReactNode;
  icon?: ReactNode;
  href?: string;
  target?: string;
  disabled?: boolean;
  size?: string;
  onClick?: any;
  className?: string;
}

export interface ButtonTagProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  children?: ReactNode;
  icon?: ReactNode;
  disabled?: boolean;
  size?: string;
  onClick?: any;
  className?: string;
}

const secondaryColorStyles: Record<Color, string> = {
  primary: css`
    --crc-button-bg: ${cvar('color-button-default')};
    --crc-button-fg: ${cvar('color-button-primary')};
    --crc-button-border: ${cvar('color-button-primary')};
    --crc-button-bg-hover: ${cvar('color-button-secondary-hover')};
    --crc-button-bg-active: ${cvar('color-button-secondary-active')};
  `,
  danger: css`
    --crc-button-bg: ${colors.white};
    --crc-button-fg: ${colors.danger.base};
    --crc-button-border: ${colors.danger.base};
    --crc-button-fg-hover: ${colors.white};
    --crc-button-bg-hover: ${colors.danger.darker};
    --crc-button-border-hover: ${colors.danger.darker};
    --crc-button-fg-active: ${colors.white};
    --crc-button-bg-active: ${colors.danger.darkest};
    --crc-button-border-active: ${colors.danger.darkest};
  `,
};

const colorStyles: Record<Variant, Record<Color, string>> = {
  default: secondaryColorStyles,
  primary: {
    primary: css`
      --crc-button-bg: ${cvar('color-interactive')};
      --crc-button-fg: ${cvar('color-text-inverse')};
      --crc-button-border: ${cvar('color-interactive')};
      --crc-button-bg-hover: ${cvar('color-button-primary-hover')};
      --crc-button-border-hover: ${cvar('color-button-primary-hover')};
      --crc-button-bg-active: ${cvar('color-button-primary-active')};
      --crc-button-border-active: ${cvar('color-button-primary-active')};
    `,
    danger: css`
      --crc-button-bg: ${colors.danger.base};
      --crc-button-fg: ${colors.white};
      --crc-button-border: ${colors.danger.base};
      --crc-button-bg-hover: ${colors.danger.darker};
      --crc-button-border-hover: ${colors.danger.darker};
      --crc-button-bg-active: ${colors.danger.darkest};
      --crc-button-border-active: ${colors.danger.darkest};
    `,
  },
  secondary: secondaryColorStyles,
  link: {
    primary: css`
      --crc-button-bg: transparent;
      --crc-button-fg: ${colors.ocean.base};
      --crc-button-bg-hover: ${cvar('color-button-secondary-hover')};
      --crc-button-bg-active: ${cvar('color-button-secondary-active')};
    `,
    danger: css`
      --crc-button-bg: transparent;
      --crc-button-fg: ${colors.danger.base};
      --crc-button-fg-hover: ${colors.white};
      --crc-button-bg-hover: ${colors.danger.darker};
      --crc-button-border-hover: ${colors.danger.darker};
      --crc-button-fg-active: ${colors.white};
      --crc-button-bg-active: ${colors.danger.darkest};
      --crc-button-border-active: ${colors.danger.darkest};
    `,
  },
  anchor: {
    primary: css`
      --crc-button-bg: transparent;
      --crc-button-fg: ${colors.ocean.base};
    `,
    danger: css`
      --crc-button-bg: transparent;
      --crc-button-fg: ${colors.danger.base};
    `,
  },
};

const baseButtonStyles = css`
  display: inline-block;
  text-align: center;
  vertical-align: middle;
  cursor: pointer;
  border-radius: 2px;
  border: 1px solid var(--crc-button-border, transparent);
  color: var(--crc-button-fg);
  background: var(--crc-button-bg);

  &:hover {
    color: var(--crc-button-fg-hover, var(--crc-button-fg));
    background: var(--crc-button-bg-hover, var(--crc-button-bg));
    border-color: var(--crc-button-border-hover, var(--crc-button-border));
    color: var(--crc-button-fg-hover, var(--crc-button-fg));
  }

  &:active {
    color: var(--crc-button-fg-active, var(--crc-button-fg));
    background: var(--crc-button-bg-active, var(--crc-button-bg));
    border-color: var(--crc-button-border-active, var(--crc-button-border));
    color: var(--crc-button-fg-active, var(--crc-button-fg));
  }
`;

const linkButtonStyle = css`
  border-color: transparent;
  background: transparent;
  text-decoration: none;
  text-shadow: none;

  &:hover,
  &:active {
    border-color: transparent;
    text-decoration: none;
  }
`;

const anchorStyle = css`
  border-color: transparent;
  background: transparent;
  font-weight: 400;

  &:hover,
  &:active {
    background: transparent;
    border-color: transparent;
    text-decoration: underline;
  }
`;

const disabledAnchorStyle = css`
  color: ${cvar('color-text-disabled-dark')};
  cursor: not-allowed;

  &:hover,
  &:active {
    color: ${cvar('color-text-disabled-dark')};
  }
`;

const disabledButtonStyle = css`
  background: ${cvar('color-button-disabled')};
  opacity: 0.65;
  color: ${cvar('color-text-disabled-dark')};
  border-color: transparent;
  cursor: not-allowed;

  &:hover {
    background: ${cvar('color-button-disabled')};
    opacity: 0.65;
    color: ${cvar('color-text-disabled-dark')};
    border-color: transparent;
    cursor: not-allowed;
  }
`;

const blockLevelButtonStyle = css`
  width: 100%;
  display: block;
`;

const btnIconSizeCss = (size: Size) => {
  if (size === 'lg')
    return css`
      padding: ${cvar('spacing-16')};
    `;
  if (size === 'sm')
    return css`
      padding: ${cvar('spacing-8')};
    `;
  return css`
    padding: ${cvar('spacing-12')};
  `;
};

const btnSizeCss = (size: Size) => {
  if (size === 'lg')
    return css`
      padding: ${cvar('spacing-12')} ${cvar('spacing-32')};
    `;
  if (size === 'sm')
    return css`
      padding: ${cvar('spacing-4')} ${cvar('spacing-12')};
    `;
  return css`
    padding: ${cvar('spacing-8')} ${cvar('spacing-24')};
  `;
};

const btnFontCss = (size: Size) => {
  if (size === 'lg')
    return css`
      font: ${cvar('text-button-large')};
    `;
  if (size === 'sm')
    return css`
      font: ${cvar('text-button-small')};
    `;
  return css`
    font: ${cvar('text-button-medium')};
  `;
};

const buttonClassTypes = (variant: Variant) => {
  switch (variant) {
    case 'link':
      return linkButtonStyle;
    case 'anchor':
      return anchorStyle;
    default:
      return baseButtonStyles;
  }
};

const disabledButtonClassTypes = (variant: Variant) => {
  switch (variant) {
    case 'anchor':
      return `${anchorStyle} ${disabledAnchorStyle}`;
    default:
      return disabledButtonStyle;
  }
};

const aTag = ({ children, icon, className, ...rest }: ATagProps) => (
  <a {...rest} className={cx('crc-anchor', className)}>
    {icon || children}
  </a>
);

const buttonTag = ({ children, icon, className, ...rest }: ButtonTagProps) => (
  <button {...rest} className={cx('crc-button', className)}>
    {icon || children}
  </button>
);

export const Button = ({
  blockLevel = false,
  className,
  disabled = false,
  href,
  icon: originalIcon,
  onClick,
  size = 'default',
  variant = 'default',
  target,
  color = 'primary',
  ...rest
}: ButtonProps) => {
  const icon =
    originalIcon &&
    React.cloneElement(originalIcon as ReactElement<any, string | JSXElementConstructor<any>>, {
      style: { display: 'block' },
    });

  const variantCss = cx(
    colorStyles[variant]?.[color],
    btnFontCss(size),
    baseButtonStyles,
    disabled ? disabledButtonClassTypes(variant) : buttonClassTypes(variant),
    variant === 'anchor' ? '' : icon ? btnIconSizeCss(size) : btnSizeCss(size),
    { [blockLevelButtonStyle]: blockLevel },
    className,
  );

  return href
    ? aTag({ href, target, icon, disabled, size, onClick, className: variantCss, ...rest })
    : buttonTag({ disabled, icon, size, onClick, className: variantCss, ...rest });
};
