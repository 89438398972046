import _ from 'lodash';
import moment, { Moment } from 'moment';
import { InventoryResponse } from '../services/inventory';

export const isInventoryAvailable = (
  inventoryResponse: InventoryResponse,
  requestDateTime: Moment,
  requestedQuantity: number,
) => {
  const immediatelyAvailable = inventoryResponse.immediateQuantity >= requestedQuantity;

  const availableByRequestTime = inventoryResponse.futureAvailability.some(availability => {
    const availableDate = moment(availability.availabilityDate);
    return availableDate.isBefore(requestDateTime) && availability.quantity >= requestedQuantity;
  });

  return immediatelyAvailable || availableByRequestTime;
};

export const getInventoryAvailableDate = (
  inventoryResponse: InventoryResponse,
  requestDateTime: Moment,
  requestedQuantity: number,
): Moment | undefined => {
  const futureAvailableDates = inventoryResponse.futureAvailability
    .filter(
      availability =>
        moment(availability.availabilityDate).isAfter(requestDateTime) && availability.quantity >= requestedQuantity,
    )
    .map(availability => moment(availability.availabilityDate));

  const earliestAvailabileDate = _.orderBy(futureAvailableDates, date => date.unix())[0];

  return earliestAvailabileDate;
};
