import { DEFAULT_FULFILLMENT_CONFIGURATION_UI_URL } from '../constants';
import fetchWithAuth from './fetchWithAuth';
import buildUrl from 'build-url';

export type FulfillmentConfiguration = {
  name: string;
  usesCCMData: boolean;
  _links: {
    ui: {
      href: string;
    };
  };
};

type FulfillmentConfigurationResponse = {
  name: string;
  fulfillmentConfigurationId: string;
  _links: {
    currentVersion: {
      name: string;
    };
  };
};

type Destination = {
  country: string;
  postalCodeRanges: { start: string; end: string }[];
  shippingDays: number;
};

type FulfillmentDeliveryOptionResponse = {
  id: string;
  name: string;
  checkFeasibility: boolean;
  destinations: Destination[];
  createdAt: string;
  createdBy: string;
  modifiedAt: string;
  modifiedby: string;
  _links: {
    account: {
      href: string;
    };
    self: {
      href: string;
    };
    ['latest-version']: {
      href: string;
    };
  };
};

type FCVersion = {
  _links: {
    fulfillmentConfiguration: {
      href: {
        v1: string;
      };
    };
    fulfillmentDeliveryOption?: {
      href: {
        v1: string;
      };
    };
  };
};

export const getFulfillmentConfiguration = async (
  accessToken: string,
  fulfillmentConfigurationUrl: string,
): Promise<FulfillmentConfiguration> => {
  const fcVersion = await fetchWithAuth<FCVersion>({ accessToken, endpointUrl: fulfillmentConfigurationUrl });
  const fc = await fetchWithAuth<FulfillmentConfigurationResponse>({
    accessToken,
    endpointUrl: fcVersion._links.fulfillmentConfiguration.href.v1,
  });

  const fdo = fcVersion._links.fulfillmentDeliveryOption
    ? await fetchWithAuth<FulfillmentDeliveryOptionResponse>({
        accessToken,
        endpointUrl: fcVersion._links.fulfillmentDeliveryOption.href.v1,
      })
    : undefined;

  const uiBaseUrl =
    (process.env.REACT_APP_FULFILLMENT_CONFIGURATION_UI_URL as string) || DEFAULT_FULFILLMENT_CONFIGURATION_UI_URL;
  const uiHref = buildUrl(uiBaseUrl, {
    path: `fulfillmentconfiguration/${fc.fulfillmentConfigurationId}/versions/${fc._links.currentVersion.name}`,
  });

  const links = {
    ui: {
      href: uiHref,
    },
  };

  return { ...fcVersion, name: fc.name, usesCCMData: fdo ? fdo.checkFeasibility : true, _links: links };
};
