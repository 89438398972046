import React from 'react';
import PropTypes from 'prop-types';
import { cx } from '@emotion/css';
import { useTimer } from './useTimer';
import { stepupStyles, timerStyle } from './styles';
import IconStepUpLogin from '@cimpress-technology/react-streamline-icons/lib/IconStepUpLogin';

export const StepupTimer = props => {
  const { remainingTime, openStepUpAuthModal, showStepupAuthModal, isLoggedIn } = props;
  const { formattedMinutes, formattedSeconds, remainingSeconds } = useTimer({ remainingTime });
  if (remainingSeconds > 0 && isLoggedIn) {
    return (
      <div className={cx('timer-container', timerStyle)}>
        <span className={cx('title-timer', timerStyle)}>Step Up Session</span>
        <span className={cx('min-timer', timerStyle)}>{formattedMinutes}</span>
        <span className={cx('sec-timer', timerStyle)}>{formattedSeconds}</span>
      </div>
    );
  }
  if (isLoggedIn) {
    return (
      <button className={cx('stepup-btn', stepupStyles)} onClick={openStepUpAuthModal}>
        <IconStepUpLogin color={showStepupAuthModal ? '#357fac' : undefined} size="2x" />
      </button>
    );
  }
};

StepupTimer.propTypes = {
  remainingTime: PropTypes.number.isRequired,
  openStepUpAuthModal: PropTypes.func.isRequired,
  showStepupAuthModal: PropTypes.bool,
  isLoggedIn: PropTypes.bool,
};
