import React from 'react';
import { css } from '@emotion/css';
import { colors } from '@cimpress/react-components';
import {
  IconCheckCircleAlt,
  IconInformationCircle,
  IconRemoveCircle,
  IconDeliveryTruckClock,
} from '@cimpress-technology/react-streamline-icons';
import moment, { Moment } from 'moment';

import { InventoryResponse } from '../services/inventory';
import { getInventoryAvailableDate, isInventoryAvailable } from '../helpers/inventory';
import { Tooltip } from '@cimpress/react-components';

const inStockTextCss = (color: string) => css`
  margin-left: 5px;
  color: ${color};
  font-weight: bold;
`;

const availabilityTextCss = css`
  margin-left: 5px;
  color: ${colors.shale};
`;

const inventoryAvailabilityCss = css`
  display: flex;
  align-items: center;
  gap: 0px;
  margin-top: 10px;
`;

type InventoryAvailabilityProps = {
  orderCreatedDate: Moment;
  requestedQuantity: number;
  inventory: InventoryResponse | undefined;
};

const InventoryAvailability = ({ orderCreatedDate, requestedQuantity, inventory }: InventoryAvailabilityProps) => {
  if (!inventory) {
    return (
      <div className={inventoryAvailabilityCss}>
        <IconInformationCircle size="2x" weight="fill" color={colors.alloy} />
        <div className={inStockTextCss(colors.alloy)}>No inventory information</div>
      </div>
    );
  }

  const isAvailable = isInventoryAvailable(inventory, orderCreatedDate, requestedQuantity);
  const futureAvailableDate = getInventoryAvailableDate(inventory, orderCreatedDate, requestedQuantity);
  const formattedFutureAvailableDate = futureAvailableDate?.format('MMM Do YYYY');
  const mappings = inventory.debugDetails?.supplierMappings;

  let availabilityDisplay;

  if (isAvailable) {
    availabilityDisplay = (
      <>
        <IconCheckCircleAlt size="2x" weight="fill" color={colors.success.base} />
        <div className={inStockTextCss(colors.success.base)}>In stock</div>
        <div className={availabilityTextCss}>{'(available quantity: ' + inventory.immediateQuantity + ')'}</div>
      </>
    );
  } else if (futureAvailableDate) {
    availabilityDisplay = (
      <>
        <IconDeliveryTruckClock size="2x" weight="fill" color={colors.warning.base} />
        <div className={inStockTextCss(colors.warning.base)}>Stock on the way</div>
        <div className={availabilityTextCss}>{'(available starting: ' + formattedFutureAvailableDate + ')'}</div>
      </>
    );
  } else {
    availabilityDisplay = (
      <>
        <IconRemoveCircle size="2x" weight="fill" color={colors.danger.base} />
        <div className={inStockTextCss(colors.danger.base)}>Out of stock</div>
      </>
    );
  }

  const inventoryTooltip = (
    <div style={{ textAlign: 'left' }}>
      <div>Now: {inventory.immediateQuantity}</div>
      {Boolean(inventory.futureAvailability.length) &&
        inventory.futureAvailability.map((a, index) => (
          <div key={index}>
            {moment(a.availabilityDate).format('MMM Do YYYY')}: {a.quantity}
          </div>
        ))}
      {mappings?.length && (
        <div style={{ marginTop: '10px' }}>
          Supplier: {mappings[0].supplierName}
          <br />
          SKU: {mappings[0].supplierSku}{' '}
        </div>
      )}
    </div>
  );

  return (
    <div className={inventoryAvailabilityCss}>
      {availabilityDisplay}
      <Tooltip contents={inventoryTooltip} style={{ marginLeft: '5px' }}>
        <IconInformationCircle size="sm" weight="fill" color={colors.ocean.base} />
      </Tooltip>
    </div>
  );
};

export default InventoryAvailability;
