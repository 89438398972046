import { useQuery } from 'react-query';
import { getProduct } from '../services/introduction';

export const useProduct = (accessToken: string, productConfigurationUrl: string | undefined) => {
  return useQuery(
    ['product', productConfigurationUrl],
    () => getProduct(accessToken, productConfigurationUrl as string),
    {
      enabled: !!productConfigurationUrl,
    },
  );
};
