import { DEFAULT_FAMILY_SERVICE_URL, DEFAULT_INTRODUCTION_SERVICE_URL } from '../constants';
import fetchWithAuth from './fetchWithAuth';

type Attribute = {
  class: string;
  attributeKey: string;
  attributeValue: string;
};

type ProductConfiguration = {
  mcpSku: string;
  attributes: Attribute[];
};

type IntroductionResponse = {
  referenceId: string;
  name: string;
  description: string;
};

type FamilyResponse = {
  familySummary: {
    scenes: {
      renderedSceneUri: string;
    }[];
  };
};

export type Product = {
  mcpSku: string;
  name: string;
  description: string;
  sceneUrl: string | undefined;
};

export const getProduct = async (accessToken: string, productConfigurationUrl: string): Promise<Product> => {
  const productConfiguration = await fetchWithAuth<ProductConfiguration>({
    accessToken,
    endpointUrl: productConfigurationUrl,
  });

  const introductionServiceUrl =
    (process.env.REACT_APP_INTRODUCTION_SERVICE_URL as string) || DEFAULT_INTRODUCTION_SERVICE_URL;
  const introductionResponse = await fetchWithAuth<IntroductionResponse>({
    accessToken,
    endpointUrl: introductionServiceUrl,
    route: `/v1/products/${productConfiguration.mcpSku}`,
  });

  const familyServiceUrl = (process.env.REACT_APP_FAMILY_SERVICE_URL as string) || DEFAULT_FAMILY_SERVICE_URL;
  const familyResponse = await fetchWithAuth<FamilyResponse>({
    accessToken,
    endpointUrl: familyServiceUrl,
    route: '/v2/product-families/mcpsku',
    params: { mcpSku: productConfiguration.mcpSku },
  }).catch(() => undefined);

  return {
    mcpSku: introductionResponse.referenceId,
    name: introductionResponse.name,
    description: introductionResponse.description,
    sceneUrl: familyResponse?.familySummary?.scenes?.[0]?.renderedSceneUri,
  };
};
