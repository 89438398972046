import React, { useCallback, useState } from 'react';
import { Modal, Button, TextField } from '@cimpress/react-components';
import { cx } from '@emotion/css';
import PropTypes from 'prop-types';
import { modalStyle } from './styles';
import {
  STEPUP_AUTHENTICATION_REMINDER_KEY,
  STEPUP_AUTHENTICATION_EXPIRY_NOTIFICATION_KEY,
  MIN_ALLOWED_TIME,
  MAX_ALLOWED_TIME,
  DEFAULT_TIME,
} from './StepupAuthConfig';

export const StepupAuthModal = props => {
  const { showModal, handleClose, onStepUpAuthenticationConfirm } = props;
  const [ttl, setTtl] = useState(DEFAULT_TIME);
  const [error, setError] = useState('');

  const loginClickHandler = useCallback(
    () => {
      if (!error) {
        handleClose();
        onStepUpAuthenticationConfirm(ttl);
        localStorage.removeItem(STEPUP_AUTHENTICATION_REMINDER_KEY);
        localStorage.removeItem(STEPUP_AUTHENTICATION_EXPIRY_NOTIFICATION_KEY);
      }
    },
    [error, handleClose, onStepUpAuthenticationConfirm, ttl]
  );

  const onTextInputChange = useCallback(e => {
    const val = e.target.value;
    if (Number(val) > MAX_ALLOWED_TIME || Number(val) < MIN_ALLOWED_TIME) {
      setError(`Time should be between ${MIN_ALLOWED_TIME} to ${MAX_ALLOWED_TIME} minutes`);
    } else {
      setError('');
    }
    setTtl(val);
  }, []);

  const modalCloseHandler = useCallback(
    () => {
      handleClose();
      setTtl(DEFAULT_TIME);
      setError('');
    },
    [handleClose]
  );

  return (
    <Modal
      status={error ? 'danger' : 'success'}
      show={showModal}
      title={<p className={cx('modal-title', modalStyle)}>Set time in minutes for step up</p>}
      onRequestHide={modalCloseHandler}
      closeButton={true}
      className="error-message"
      footer={<Button onClick={loginClickHandler}>Login</Button>}>
      <TextField type="number" label="Time in minutes" value={ttl} onChange={onTextInputChange} />
      <p className={cx('error-message', modalStyle)}>{error}</p>
    </Modal>
  );
};

StepupAuthModal.propTypes = {
  showModal: PropTypes.bool,
  handleClose: PropTypes.func.isRequired,
  onStepUpAuthenticationConfirm: PropTypes.func.isRequired,
};
