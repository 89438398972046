import { useQuery } from 'react-query';
import { fetchWithAuth } from '../services/fetchWithAuth';

export const useResource = <T>(accessToken: string, url: string | undefined) => {
  return useQuery(
    ['url', url],
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    () => fetchWithAuth<T>({ accessToken, endpointUrl: url! }),
    { enabled: !!url },
  );
};
