import { colors } from '@cimpress/react-components';
import { css } from '@emotion/css';

export const navbar = css`
  display: flex;
  align-items: center;
  border-radius: 0;
  padding: 0 24px;
`;

export const userBox = css`
  a {
    display: flex;
    align-items: center;
    text-decoration: none;
    div {
      text-align: right;
    }
    img.avatar {
      width: 36px;
      height: 36px;
      border-radius: 50%;
      margin-left: 10px;
    }
    .username {
      color: ${colors.cobalt.base};
    }
    .organization {
      /* font-size: $font-size-extra-small; */
      font-size: 12px;
      color: ${colors.shale};
    }
  }
  // right justify profile dropdown
  .dropdown-menu {
    right: 0;
    left: auto;
    width: 100%;
  }
`;

export const displayBlock = css(`display: block;`);

export const hamburgerCss = css`
  border: 1px solid ${colors.silver};
  padding: 1px;
  &:hover {
    background: ${colors.horizon};
  }
`;

export const logoCss = css`
  text-decoration: none;
  margin-right: auto;
  margin-left: 20px;
  padding: 0;
  height: auto;
  svg {
    ${displayBlock};
  }
`;

export const navbarCss = css`
  position: relative;

  clear: both;
  width: 100%;
  display: flex;
  align-items: center;
  border-radius: 0;
  border: none;
  padding: 0 20px;

  min-height: 50px;
  justify-content: space-between;
  box-shadow: none;
  margin-bottom: 0;

  background: #fff;
  border-bottom: 1px solid #e1e6eb;

  &.mcp-navbar-subtitle {
    box-shadow: 0 2px 4px 0 rgb(105 117 130 / 16%), 0 2px 4px 0 rgb(105 117 130 / 23%);
    margin-bottom: 16px;
  }

  &.mcp-navbar-subtitle h2,
  &.mcp-navbar-subtitle h2 > a {
    margin: 0;
    color: ${colors.persimmon.base};
  }

  .mcp-nav-tabs {
    list-style: none;
    display: flex;
    align-items: center;
    margin-bottom: 0;

    .link-divider {
      border-left: 1px solid ${colors.alloy};
      margin: 10px 20px;
      width: 1px;
      height: 30px;
    }
    & > li {
      & > a {
        color: ${colors.ocean.base};
        text-decoration: none;
        padding: 16px;
        margin: 0;
        display: inline-block;
        line-height: 1.286;
      }
    }
    & > li.icon-link > a:after {
      display: none;
    }
  }

  .mcp-nav-tabs .dropdown-menu {
    margin-top: 0;
  }
  .nav .open > a,
  .nav .open > a:hover,
  .nav .open > a:focus,
  .nav > li > a:hover,
  .nav > li > a:focus {
    background-color: ${colors.horizon};
    &:after {
      transform: scale(1);
    }
  }
  .mcp-nav-tabs > li.active > a,
  .mcp-nav-tabs > li.active > a:hover,
  .mcp-nav-tabs > li.active > a:focus {
    background-color: ${colors.horizon};
  }
  .mcp-nav-tabs > li > a:hover {
    background-color: transparent;
  }

  .navbar-toggle {
    position: relative;
    float: right;
    padding: 9px 10px;
    background-color: transparent;
    background-image: none; // Reset unusual Firefox-on-Android default style; see https://github.com/necolas/normalize.css/issues/214
    border: 1px solid transparent;

    &:focus {
      outline: 0;
    }
  }
`;

export const mobileSize = css`
  .mcp-navbar-subtitle {
    flex-wrap: wrap;
    align-items: center;
  }
  .navbar-toggle {
    margin-left: auto;
    display: flex;
    align-items: center;
    color: ${colors.slate};
    .caret {
      color: ${colors.ocean.base};
      margin-left: 8px;
    }
  }
  .mcp-nav-tabs {
    flex-direction: column;
  }
  .crc-header-nav-links {
    display: none;
  }
`;

export const nonProdWarning = css`
  box-shadow: 0 4px 0 0 ${colors.danger.base};
`;

export const sidenavStyles = css`
  &&.sidenav {
    background-color: ${colors.cobalt.base};
    color: ${colors.alloy};
    width: 340px;
    height: 100vh;
    position: fixed;
    left: -341px;
    z-index: 2000;
    overflow: hidden;
    transition: 400ms;
    &.open {
      left: 0px;
      transition: 400ms;
    }

    hr {
      border-color: ${colors.alloy};
      margin: 0 15px 15px 20px;
    }

    //Child navbar-inverse to contain icon and logo
    .navbar-inverse {
      background: ${colors.cobalt.base};
      border-bottom: none;
      display: flex;
      width: 100%;
      align-items: center;
      padding: 0 20px;
      min-height: 50px;
    }

    .navbar-brand {
      text-decoration: none;
      margin-right: auto;
      margin-left: 20px;
      padding: 0;
      height: auto;
    }

    .hamburger,
    .hamburger:hover {
      cursor: pointer;
      background: none;
      border: none;
    }
    .list-group {
      padding-left: 0;
      margin-bottom: 20px;
      box-shadow: none;
      width: 100%;
      margin-left: 0;
      background: none;
      .list-group-item {
        position: relative;
        display: flex;
        align-items: center;
        line-height: 1.286;
        cursor: pointer;
        svg {
          fill: ${colors.alloy};
        }
        color: ${colors.alloy};
        background: none;
        border: none;
        padding: 13px 20px;
        &.level-two {
          padding-left: 55px;
          background: #2d343d;
        }
        .ij-icon,
        i {
          margin-right: 21px;
          margin-bottom: 2px;
        }
        i.pull-right,
        .ij-icon.pull-right {
          margin-right: 0;
          margin-left: 20px;
        }
        &:hover,
        &:active {
          svg {
            fill: ${colors.white};
          }
          color: ${colors.white};
        }
        &:active {
          background: #2d343d;
          &.level-two {
            color: ${colors.white};
            background: ${colors.cobalt.darker};
          }
        }
      }
    }
    .list-group a::before {
      content: '';
      background: ${colors.persimmon.base};
      height: 100%;
      position: absolute;
      width: 4px;
      left: 0px;
      bottom: 0px;
      transition: all 250ms ease 0s;
      transform: scale(0);
    }
    .list-group a:active::before,
    .list-group a:hover::before,
    .list-group a.level-two::before {
      transform: scale(1);
    }
    .list-group a:active,
    .list-group a:hover,
    .list-group a.level-two {
      text-decoration: none;
    }
  }

  .sidenav-scrim {
    height: 100vh;
    width: 100vw;
    background-color: ${colors.white};
    top: 0;
    left: 0;
    position: fixed;
    opacity: 0;
    z-index: 1090;
    pointer-events: none;
    transition: opacity 400ms;
    transition-delay: 10ms;
    &.open {
      opacity: 0.5;
      transition: opacity 400ms;
      transition-delay: 10ms;
      pointer-events: all;
    }
  }

  @keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 0.5;
    }
  }

  @keyframes fadeOut {
    0% {
      opacity: 0.5;
    }
    100% {
      opacity: 0;
    }
  }
`;

export const flexBreak = css`
  flex-basis: 100%;
  height: 0;
`;

export const modalStyle = css`
  &&.error-message {
    color: #d24a35;
  }
  &&.modal-title {
    font-size: 24px;
    color: #f0563a;
    font-weight: 400;
  }
`;

export const stepupStyles = css`
  &&.stepup-btn {
    background: #ffffff;
    border: 0px;
    padding: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 5px;
  }
`;

export const timerStyle = css`
  &&.timer-container {
    font-size: 13px;
    margin-right: 5px;
    background: #f4f4f4;
    border-radius: 4px;
    padding: 4px 8px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .title-timer {
    margin-right: 5px;
    color: #357fac;
    font-size: 14px;
  }

  .min-timer {
    padding: 4px;
    margin-right: 2px;
    border-radius: 50%;
    color: #357fac;
    background: #fff;
    font-weight: 600;
  }

  .sec-timer {
    padding: 4px;
    border-radius: 50%;
    color: #357fac;
    background: #fff;
    font-weight: 600;
  }
`;
