import React from 'react';

import { Button } from '@cimpress/react-components';
import { DeliverableDatesRequest } from '../helpers/getDeliverableDatesRequest';

type ViewDateDiagnosticsButtonProps = {
  deliverableDatesRequest: DeliverableDatesRequest;
};

const ViewLogisticsDiagnosticsButton = ({ deliverableDatesRequest }: ViewDateDiagnosticsButtonProps) => {
  const encodedRequest = encodeURIComponent(JSON.stringify(deliverableDatesRequest));
  const deliveryDatesUrl = `https://shiprec.logistics.cimpress.io/deliverableDates?r=${encodedRequest}`;

  const onClick = (e: any) => {
    e.stopPropagation();
  };

  return (
    <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: '8px' }}>
      <Button variant="anchor" href={deliveryDatesUrl} target="_blank" onClick={onClick}>
        View Logistics Diagnostics
      </Button>
    </div>
  );
};

export default ViewLogisticsDiagnosticsButton;
