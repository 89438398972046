import { useQuery } from 'react-query';
import { getFulfillmentConfiguration } from '../services/fulfillmentConfiguration';

export const useFulfillmentConfiguration = (accessToken: string, fulfillmentConfigurationUrl: string | undefined) => {
  return useQuery(
    ['fulfillmentConfiguration', fulfillmentConfigurationUrl],
    () => getFulfillmentConfiguration(accessToken, fulfillmentConfigurationUrl as string),
    { enabled: !!fulfillmentConfigurationUrl },
  );
};
