import { Moment } from 'moment';
import fetchWithAuth from './fetchWithAuth';
import groupBy from 'lodash/groupBy';
import sortBy from 'lodash/sortBy';
import map from 'lodash/map';
import pickBy from 'lodash/pickBy';
import isEmpty from 'lodash/isEmpty';
import moment from 'moment';
import { Caveat } from '@cimpress-technology/caveats';
import { DEFAULT_ITEM_DELIVERY_POSSIBILITIES_SERVICE_URL } from '../constants';

export type AddressType = 'residential' | 'commercial' | 'logisticsHub' | 'pickupPoint';

export type DebugBranchRequest = {
  productConfigurationUrl: string;
  merchantId: string;
  quantity: number;
  country?: string;
  postalCode?: string;
  pickupPointUrl?: string;
  isPOBox?: boolean;
  addressType?: AddressType;
  minutesToOrderSubmittal?: number;
  requestDateTime?: Moment;
  fulfillmentCapabilities?: string[];
  carrierServices?: string[];
  carrierServiceCapabilities?: string[];
  currencyCode?: string;
  ignoreInTransitInventory?: boolean;
};

export type Price = {
  product?: {
    price?: number;
    currency?: string;
  };
  shipping?: {
    price?: number;
    currency?: string;
  };
};

export type DeliverableDate = {
  date: Moment;
  expiryDate: string;
  price?: Price;
};

export type BranchLinks = {
  cutoffTimes?: {
    href: string;
  };
  fulfiller?: {
    href: string;
  };
  fulfillmentConfiguration?: {
    href: string;
  };
  fulfillmentLocation?: {
    href: string;
  };
  inventory?: {
    href: string;
  };
  packEvaluation?: {
    href: string;
  };
  productConfiguration: {
    href: string;
  };
  productionDelay?: {
    href: string;
  };
  productionTimeEvaluation?: {
    href: string;
  };
  shipDate?: {
    href: string;
  };
};

export type DebugBranch = {
  caveats: Caveat[];
  deliverableDates: DeliverableDate[];
  _links: BranchLinks;
};

export type DebugBranchResponse = {
  _embedded: {
    item: DebugBranch[];
  };
};

export const getDebugBranches = async (accessToken: string, request: DebugBranchRequest): Promise<DebugBranch[]> => {
  const params = {
    productConfigurationUrl: request.productConfigurationUrl,
    merchantId: request.merchantId,
    quantity: request.quantity.toString(),
    country: request.country,
    postalCode: request.postalCode,
    pickupPointUrl: request.pickupPointUrl,
    isPOBox: request.isPOBox?.toString(),
    addressType: request.addressType,
    minutesToOrderSubmittal: request.minutesToOrderSubmittal?.toString(),
    requestDateTime: request.requestDateTime?.format(),
    fulfillmentCapability: request.fulfillmentCapabilities,
    carrierService: request.carrierServices,
    carrierServiceCapability: request.carrierServiceCapabilities,
    currencyCode: request.currencyCode,
    ignoreInTransitInventory: request.ignoreInTransitInventory?.toString(),
  };

  const response = await fetchWithAuth<DebugBranchResponse>({
    accessToken,
    endpointUrl:
      (process.env.REACT_APP_ITEM_DELIVERY_POSSIBILITIES_SERVICE_URL as string) ||
      DEFAULT_ITEM_DELIVERY_POSSIBILITIES_SERVICE_URL,
    route: '/v6/deliveryPossibilities/branches',
    params: pickBy(params, param => !isEmpty(param)),
  });

  const branches = response._embedded.item;

  // For QDA-Pixart, there can be duplicate deliverable dates per delivery option. We don't want those. If there
  // happen to be duplicate deliverable dates, keep the one with the cheapest price and filter the rest.
  return branches.map(branch => {
    const deliverableDates = branch.deliverableDates.map(deliverableDate => {
      return { ...deliverableDate, date: moment(deliverableDate.date) };
    });
    const groupByDate = groupBy(deliverableDates, 'date');

    const newDeliverableDates = map(
      groupByDate,
      group => sortBy(group, branch => (branch?.price?.product?.price ?? 0) + (branch?.price?.shipping?.price ?? 0))[0],
    );

    return { ...branch, deliverableDates: newDeliverableDates };
  });
};
