import React from 'react';
import { Robot } from '@cimpress/react-components';
import { css } from '@emotion/css';

const robotCss = css`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 180px;
  margin-bottom: 20px;
`;

const noCaveatsTextCss = css`
  display: flex;
  justify-content: center;
  font-weight: bold;
`;

export const NoCaveats = () => {
  return (
    <div>
      <div className={robotCss}>
        <Robot status="success" size="sm" />
      </div>
      <div className={noCaveatsTextCss}>No Caveats</div>
    </div>
  );
};
