import { useEffect, useState } from 'react';

const formatTime = timeDifference => {
  const minutes = Math.floor(timeDifference / 60);
  const remainingSeconds = Math.floor(timeDifference % 60);

  const formattedMinutes = String(minutes).padStart(2, '0');
  const formattedSeconds = String(remainingSeconds).padStart(2, '0');
  return { formattedMinutes, formattedSeconds, remainingSeconds: timeDifference };
};
let interval;
export const useTimer = props => {
  const { remainingTime } = props;
  const [now, setNow] = useState(formatTime(remainingTime / 1000));
  useEffect(
    () => {
      interval && clearInterval(interval);
      if (remainingTime > 0) {
        let timeDifference = remainingTime / 1000;
        interval = setInterval(() => {
          setNow(formatTime(timeDifference));
          if (Math.ceil(timeDifference) === 0) {
            clearInterval(interval);
          }
          timeDifference--;
        }, 1000);
      } else {
        setNow(formatTime(0));
      }

      return () => {
        interval && clearInterval(interval);
      };
    },
    [remainingTime]
  );

  return { ...now };
};
