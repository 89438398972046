import _ from 'lodash';
import { AddressType, DebugBranch, DebugBranchRequest } from '../services/itemDeliveryPossibilities';
import moment from 'moment';
import { PackEvaluation, ShipDateResponse } from '../types';

type GetDeliverableDatesRequestProps = {
  branch: DebugBranch;
  request: DebugBranchRequest;
  packEvaluation: PackEvaluation;
  shipDateData: ShipDateResponse;
};

export type DeliverableDatesRequest = {
  fulfillmentLocationId: string;
  earliestShipDateTime: string;
  options: {
    includeCheapestOption: boolean;
    includeCostBreakDown: boolean;
    includeAvailableTimeSlots: boolean;
  };
  packDefinitions: {
    quantity: number;
    mcpPackUrl: string;
  }[];
  require: ({ carrierServiceKey: string } | { capabilityKey: string })[];
  shippingDestination:
    | {
        pickupPointUrl: string;
      }
    | {
        address: {
          postalCode: string;
          countryCode: string;
          addressType: AddressType;
        };
      };
};

const getDeliverableDatesRequest = ({
  request,
  branch,
  packEvaluation,
  shipDateData,
}: GetDeliverableDatesRequestProps): DeliverableDatesRequest => {
  const deliverableDatesRequest = {
    fulfillmentLocationId: branch._links.fulfillmentLocation?.href.substring(
      branch._links.fulfillmentLocation?.href.lastIndexOf('/') + 1,
    ),
    earliestShipDateTime: moment(shipDateData.earliestShipDateTime).toISOString(),
    options: {
      includeCheapestOption: true,
      includeCostBreakDown: true,
      includeAvailableTimeSlots: true,
    },
    packDefinitions: packEvaluation._embedded.selectedPacks.map(pack => ({
      quantity: pack.quantity,
      mcpPackUrl: pack._links.pack.href,
    })),
  };

  let deliverableDatesRequire: any[] = [];

  if (request.carrierServices && request.carrierServices.length) {
    const carrierServiceRequirements = request.carrierServices.map(carrierService => ({
      carrierServiceKey: carrierService,
    }));

    deliverableDatesRequire = deliverableDatesRequire.concat(carrierServiceRequirements);
  }
  if (request.carrierServiceCapabilities && request.carrierServiceCapabilities.length) {
    const carrierCapabilitiesRequirements = request.carrierServiceCapabilities.map(carrierCapability => ({
      capabilityKey: carrierCapability,
    }));

    deliverableDatesRequire = deliverableDatesRequire.concat(carrierCapabilitiesRequirements);
  }

  if (request.isPOBox) {
    deliverableDatesRequire.push({ capabilityKey: 'recs:cap:post-box-delivery' });
  }

  if (request.pickupPointUrl) {
    _.set(deliverableDatesRequest, 'shippingDestination.pickupPointUrl', request.pickupPointUrl);
  } else {
    _.set(deliverableDatesRequest, 'shippingDestination.address', {
      postalCode: request.postalCode,
      countryCode: request.country,
    });

    const addressType: AddressType = request.addressType === 'commercial' ? 'commercial' : 'residential';
    _.set(deliverableDatesRequest, 'shippingDestination.address.addressType', addressType);
  }

  _.set(deliverableDatesRequest, 'require', deliverableDatesRequire);

  return deliverableDatesRequest as DeliverableDatesRequest;
};

export default getDeliverableDatesRequest;
