import React, { useState, useMemo, useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import { StepupAuthModal } from './StepupAuthModal';
import { StepupAuthReminderPopup } from './StepupAuthReminderPopup';
import { StepupTimer } from './StepupTimer';
import { useVisibilityChange } from './useVisibilityChange';

export const StepupAuthentication = props => {
  const { enableStepUpAuthentication, getStepUpExpiryTime, onStepUpAuthenticationConfirm, isLoggedIn } = props;
  const [showStepupAuthModal, setshowStepupAuthModal] = useState(false);
  const { visibilityState } = useVisibilityChange();

  const remainingTime = useMemo(
    () => {
      if (isLoggedIn && getStepUpExpiryTime && visibilityState) {
        const expiryTime = getStepUpExpiryTime();
        if (expiryTime) {
          let timeDifference = expiryTime - new Date();
          return timeDifference;
        }
      }
    },
    [getStepUpExpiryTime, isLoggedIn, visibilityState]
  );

  const closeStepUpAuthModalHandler = useCallback(() => {
    setshowStepupAuthModal(false);
  }, []);

  const openStepUpAuthModalHandler = useCallback(() => {
    setshowStepupAuthModal(true);
  }, []);

  if (!enableStepUpAuthentication) {
    return null;
  }
  return (
    <>
      <div className="mx-2">
        <StepupAuthReminderPopup remainingTime={remainingTime} openStepUpAuthModal={openStepUpAuthModalHandler} />
        <StepupTimer
          isLoggedIn={isLoggedIn}
          remainingTime={remainingTime}
          openStepUpAuthModal={openStepUpAuthModalHandler}
          showStepupAuthModal={showStepupAuthModal}
        />
      </div>
      <StepupAuthModal
        showModal={showStepupAuthModal}
        handleClose={closeStepUpAuthModalHandler}
        onStepUpAuthenticationConfirm={onStepUpAuthenticationConfirm}
      />
    </>
  );
};

StepupAuthentication.propTypes = {
  enableStepUpAuthentication: PropTypes.bool,
  getStepUpExpiryTime: PropTypes.func,
  onStepUpAuthenticationConfirm: PropTypes.func,
  isLoggedIn: PropTypes.bool,
};
