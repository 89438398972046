import React, { CSSProperties, ReactElement, ReactNode, Ref, useEffect, useState } from 'react';
import { css, cx } from '@emotion/css';
import { useMemoizedId } from './utils';
import defaultTheme from './theme/theme';
import cvar from './theme/cvar';
import SuccessSvg from './icons/SuccessSvg';
import WarningSvg from './icons/WarningSvg';
import ErrorSvg from './icons/ErrorSvg';
import { PublicComponentProps } from './types';
import { useFeatureFlags } from './FeatureFlags';

const isTextTruthy = (val: any) => val !== undefined && val !== null && val.toString() !== '';

type Status = 'success' | 'warning' | 'error';

interface TextInputBaseProps<T> extends PublicComponentProps {
  status?: Status;
  helpText?: ReactNode;
  id?: string;
  inputStyle?: CSSProperties;
  label?: string;
  onChange: (event: React.ChangeEvent<T> & { isValid: boolean }) => void;
  rightAddon?: ReactElement;
  type?: 'text' | 'number' | 'password' | 'url' | 'search' | 'email' | 'tel' | 'textarea';
}

export const formGroupCss = css`
  position: relative;
  margin-bottom: ${cvar('spacing-16')};

  input {
    box-shadow: none;
    padding: ${cvar('spacing-16')};
    transition: all 0.2s ease-out;

    &:focus {
      outline: none;
      box-shadow: none;
      border-color: ${cvar('color-border-interactive')};
    }
  }
  textarea {
    box-shadow: none;
    padding: ${cvar('spacing-16')};
    height: auto;

    &:focus {
      outline: none;
      box-shadow: none;
      border-color: ${cvar('color-border-interactive')};
    }
  }
  label {
    color: ${cvar('color-text-label')};
    font-weight: normal;
    position: absolute;
    left: ${cvar('spacing-16')};
    top: ${cvar('spacing-16')};
    line-height: 16px;
    margin-bottom: 0;
    transition: all 0.2s ease-out;
    z-index: 5;

    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    max-width: 90%;
  }
`;

export const formGroupActiveCss = css`
  input,
  textarea {
    padding: ${cvar('spacing-24')} ${cvar('spacing-16')} ${cvar('spacing-8')};
  }
  label {
    top: ${cvar('spacing-4')};
    font-size: 12px;
  }
`;

export const controlLabelCss = css`
  margin-bottom: 0;
  font-size: inherit;
  line-height: 1.5;
`;

const isRequired = css`
  label:after {
    content: ' *';
    color: ${cvar('color-required-asterisk')};
  }
`;

const isDisabled = css`
  input {
    color: ${cvar('color-text-label')};
    background: ${cvar('color-textfield-disabled')};
  }
`;

const formControl = css`
  height: ${cvar('spacing-48')};
  border: 1px solid ${cvar('color-border-default')};
  position: relative;
  flex: 1 1 auto;
  width: 1%;
  margin-bottom: 0;
`;

const inputGroup = css`
  z-index: 0;
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  width: 100%;
`;
const inputGroupButton = css`
  > button {
    padding: ${cvar('spacing-12')} ${cvar('spacing-24')};
    border-bottom-right-radius: ${cvar('spacing-2')};
    border-top-right-radius: ${cvar('spacing-2')};
    line-height: 18px;
    height: ${cvar('spacing-48')};
    border-bottom-left-radius: 0px;
    border-top-left-radius: 0px;
  }
`;

const helpBlock = css`
  display: block;
  margin-top: ${cvar('spacing-4')};
`;

const textfieldColorMap: Record<Status, keyof typeof defaultTheme> = {
  success: 'color-border-success',
  warning: 'color-border-warning',
  error: 'color-border-error',
};

const statusIconSvg: Record<Status, ReactNode> = {
  success: <SuccessSvg />,
  warning: <WarningSvg />,
  error: <ErrorSvg />,
};

const statusCss = (status: Status) => css`
  input {
    box-shadow: none;
    padding-right: ${cvar('spacing-32')};
    &:focus {
      border-color: ${cvar(textfieldColorMap[status])};
    }
  }

  .crc-text-field {
    border-color: ${cvar(textfieldColorMap[status])};
  }

  svg {
    position: absolute;
    right: ${cvar('spacing-12')};
    top: ${cvar('spacing-16')};
    color: ${cvar(textfieldColorMap[status])};
    transition: all 0.2s ease-out;
  }
`;

const inputWrapper = css`
  display: flex;
  flex: 1 1 auto;
  position: relative;
`;

const noOuterMarginCss = css`
  margin-bottom: 0;
`;

export interface TextFieldProps
  extends TextInputBaseProps<HTMLInputElement>,
    Omit<React.InputHTMLAttributes<HTMLInputElement>, 'type' | 'onChange'> {
  /** Determines the HTML5 input type of the input. One of text, number, password, url, search, email, or tel. */
  type?: 'text' | 'number' | 'password' | 'url' | 'search' | 'email' | 'tel';
}

export interface TextAreaProps
  extends TextInputBaseProps<HTMLTextAreaElement>,
    // `cols` doesn't have any effect because we render the `textarea` at 100% width
    Omit<React.TextareaHTMLAttributes<HTMLTextAreaElement>, 'type' | 'onChange' | 'cols'> {
  /**
   * Applies validation to `onChange` event. If the pattern is not matched, the `isValid` property will be `false`.
   * @deprecated  */
  pattern?: string;
}

export const TextField = React.forwardRef<HTMLInputElement, TextFieldProps>(
  (
    {
      autoFocus = false,
      className = '',
      disabled = false,
      helpText,
      id,
      inputStyle,
      label,
      name,
      onBlur,
      onChange,
      onClick,
      onFocus,
      onKeyDown,
      placeholder,
      required = false,
      rightAddon,
      status,
      style,
      type = 'text',
      value = '',
      pattern,
      ...rest
    },
    ref,
  ) => {
    const { v17_noOuterSpacing } = useFeatureFlags();

    const [float, setFloat] = useState(isTextTruthy(value));

    const uniqueId = useMemoizedId({ label: `${name}-${placeholder}-${label}`, id });

    useEffect(() => {
      setFloat(isTextTruthy(value));
    }, [setFloat, value]);

    const handleBlur = (e: React.FocusEvent<HTMLInputElement>) => {
      setFloat(isTextTruthy(value));
      onBlur && onBlur(e);
    };

    const handleFocus = (e: React.FocusEvent<HTMLInputElement>) => {
      setFloat(true);
      onFocus && onFocus(e);
    };

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
      const isValid = !pattern || !!e.target.value.match(new RegExp(`^${pattern}$`));
      onChange && onChange({ ...e, isValid });
    };

    const inputId = id || uniqueId;

    const wrapperClassNames = cx('crc-text-input', formGroupCss, v17_noOuterSpacing && noOuterMarginCss, className, {
      [formGroupActiveCss]: !!label && float,
      [statusCss(status as Status)]: !!status,
      [isRequired]: !!required,
      [isDisabled]: !!disabled,
    });

    const inputProps = {
      ...rest,
      autoFocus,
      disabled,
      id: inputId,
      name,
      onBlur: handleBlur,
      onChange: handleChange,
      onClick,
      onFocus: handleFocus,
      onKeyDown,
      placeholder,
      required,
      style: inputStyle,
      value,
      pattern,
      type,
    };

    const inputElement = (
      <input {...inputProps} className={cx(formControl, 'crc-text-field')} ref={ref as Ref<HTMLInputElement>} />
    );

    const labelElement = label ? (
      <label className={controlLabelCss} htmlFor={inputId} title={label}>
        {label}
      </label>
    ) : null;

    return (
      <div className={wrapperClassNames} style={style}>
        <div className={inputGroup}>
          <div className={inputWrapper}>
            {inputElement}
            {labelElement}
            {!!status && statusIconSvg[status]}
          </div>
          {rightAddon && <span className={inputGroupButton}>{rightAddon}</span>}
        </div>
        {helpText ? <small className={helpBlock}>{helpText}</small> : null}
      </div>
    );
  },
);

TextField.displayName = 'TextField';

export const TextArea = React.forwardRef<HTMLTextAreaElement, TextAreaProps>(
  (
    {
      autoFocus = false,
      className = '',
      disabled = false,
      helpText,
      id,
      inputStyle,
      label,
      name,
      onBlur,
      onChange,
      onClick,
      onFocus,
      onKeyDown,
      placeholder,
      required = false,
      rightAddon,
      status,
      style,
      type,
      value = '',
      pattern,
      ...rest
    },
    ref,
  ) => {
    const { v17_noOuterSpacing: noOuterSpacing } = useFeatureFlags();

    const [float, setFloat] = useState(isTextTruthy(value));

    const uniqueId = useMemoizedId({ label: `${name}-${placeholder}-${label}`, id });

    useEffect(() => {
      setFloat(isTextTruthy(value));
    }, [setFloat, value]);

    const handleBlur = (e: React.FocusEvent<HTMLTextAreaElement>) => {
      setFloat(isTextTruthy(value));
      onBlur && onBlur(e);
    };

    const handleFocus = (e: React.FocusEvent<HTMLTextAreaElement>) => {
      setFloat(true);
      onFocus && onFocus(e);
    };

    const handleChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
      const isValid = !pattern || !!e.target.value.match(new RegExp(`^${pattern}$`));
      onChange && onChange({ ...e, isValid });
    };

    const inputId = id || uniqueId;

    const wrapperClassNames = cx('crc-text-input', formGroupCss, noOuterSpacing && noOuterMarginCss, className, {
      [formGroupActiveCss]: !!label && float,
      [statusCss(status as Status)]: !!status,
      [isRequired]: !!required,
      [isDisabled]: !!disabled,
    });

    const inputProps: React.TextareaHTMLAttributes<HTMLTextAreaElement> = {
      ...rest,
      autoFocus,
      disabled,
      id: inputId,
      name,
      onBlur: handleBlur,
      onChange: handleChange,
      onClick,
      onFocus: handleFocus,
      onKeyDown,
      placeholder,
      required,
      style: inputStyle,
      value,
    };

    const inputElement = (
      <textarea {...inputProps} className={cx(formControl, 'crc-text-field')} ref={ref as Ref<HTMLTextAreaElement>} />
    );

    const labelElement = label ? (
      <label className={controlLabelCss} htmlFor={inputId} title={label}>
        {label}
      </label>
    ) : null;

    return (
      <div className={wrapperClassNames} style={style}>
        <div className={inputGroup}>
          <div className={inputWrapper}>
            {inputElement}
            {labelElement}
            {!!status && statusIconSvg[status]}
          </div>
          {rightAddon && <span className={inputGroupButton}>{rightAddon}</span>}
        </div>
        {helpText ? <small className={helpBlock}>{helpText}</small> : null}
      </div>
    );
  },
);

TextArea.displayName = 'TextArea';
