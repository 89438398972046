import { useEffect, useState, useCallback } from 'react';

export const useVisibilityChange = () => {
  const [visibilityState, setVisibilityState] = useState(document.visibilityState);

  const handleVisibilityChange = useCallback(() => {
    setVisibilityState(document.visibilityState);
  }, []);

  useEffect(
    () => {
      document.addEventListener('visibilitychange', handleVisibilityChange);

      return () => {
        document.removeEventListener('visibilitychange', handleVisibilityChange);
      };
    },
    [handleVisibilityChange]
  );

  return { visibilityState };
};
