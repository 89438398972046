import React from 'react';
import { Card, colors, Tooltip } from '@cimpress/react-components';
import { css } from '@emotion/css';
import moment, { Moment } from 'moment-timezone';
import humanizeDuration from 'humanize-duration';
import { getInventoryAvailableDate, isInventoryAvailable } from '../helpers/inventory';

import {
  IconCogDouble,
  IconDeliveryTruck2,
  IconDeliveryTruckClock,
  IconShoppingCartFull,
  IconShipmentCheck,
  IconTimeClockCircle,
} from '@cimpress-technology/react-streamline-icons';
import { BranchLinks } from '../services/itemDeliveryPossibilities';
import { useResource } from '../hooks/useResource';
import Loading from './Loading';
import { InventoryResponse } from '../services/inventory';
import { ShipDateResponse } from '../types';

type ProductionTimeResponse = {
  productionTime: {
    time: number;
    unit: 'HH' | 'DD';
    feasible?: boolean;
  };
};

type OrderCreatedStep = {
  type: 'orderCreated';
  startsAt: Moment;
};

type InventoryDelayStep = {
  type: 'inventoryDelay';
  startsAt: Moment;
};

type ProductionDelayStep = {
  type: 'productionDelay';
  startsAt: Moment;
};

type ProductionTimeStep = {
  type: 'productionTime';
  startsAt: Moment;
  productionTime: ProductionTimeResponse;
};

type ShippedStep = {
  type: 'shipped';
  startsAt: Moment;
  earliestShipDate: Moment;
  earliestDeliveryDate: Moment;
};

type DeliveredStep = {
  type: 'delivered';
  startsAt: Moment;
};

type TimelineStep =
  | OrderCreatedStep
  | InventoryDelayStep
  | ProductionDelayStep
  | ProductionTimeStep
  | ShippedStep
  | DeliveredStep;

type TimelineStepType = TimelineStep['type'];

type TimelineStepTemplate = {
  icon: JSX.Element;
  hoverText: (timestamp: string) => string;
  dateFormat: string;
};

const lineStyle = css`
  border-bottom: 4px solid ${colors.info.darker};
  margin-right: 5px;
  margin-left: 5px;
  width: 100%;
  &:hover {
    border-bottom: 4px solid ${colors.info.base};
  }
`;

const iconStyle = css`
  &:hover {
    color: ${colors.slate};
  }
`;

const deliveryIconStyle = css`
  &:hover {
    color: ${colors.success.darker};
  }
  color: ${colors.success.base};
`;

const emptyTimelineCss = css`
  display: flex;
  justify-content: center;
`;

const noTimelineTextCss = css`
  font-weight: bold;
  padding: 6px 0 6px 0;
  color: ${colors.alloy};
`;

const orderCreatedTemplate: TimelineStepTemplate = {
  icon: <IconShoppingCartFull className={iconStyle} size="2x" />,
  hoverText: (timestamp: string) => `Order created at ${timestamp}, waiting for production`,
  dateFormat: 'ddd MMM DD YYYY h:mm:ss A (z)',
};

const inventoryDelayTemplate: TimelineStepTemplate = {
  icon: <IconDeliveryTruckClock className={iconStyle} size="2x" />,
  hoverText: () => `Out of stock`,
  dateFormat: 'ddd MMM DD YYYY h:mm:ss A (z)',
};

const productionDelayTemplate: TimelineStepTemplate = {
  icon: <IconTimeClockCircle className={iconStyle} size="2x" />,
  hoverText: () => `Production is delayed`,
  dateFormat: 'ddd MMM DD YYYY h:mm:ss A (z)',
};

const productionTimeTemplate: TimelineStepTemplate = {
  icon: <IconCogDouble className={iconStyle} size="2x" />,
  hoverText: (timestamp: string) => `Production starts at ${timestamp}`,
  dateFormat: 'ddd MMM DD YYYY h:mm:ss A (z)',
};

const shippedTemplate: TimelineStepTemplate = {
  icon: <IconDeliveryTruck2 className={iconStyle} size="2x" />,
  hoverText: (timestamp: string) => `Order is shipped at ${timestamp}`,
  dateFormat: 'ddd MMM DD YYYY h:mm:ss A (z)',
};

const deliveredTemplate: TimelineStepTemplate = {
  icon: <IconShipmentCheck className={deliveryIconStyle} size="2x" />,
  hoverText: (timestamp: string) => `Shipment is delivered on ${timestamp}`,
  dateFormat: 'ddd MMM DD YYYY',
};

const timelineTemplates: Map<TimelineStepType, TimelineStepTemplate> = new Map([
  ['orderCreated', orderCreatedTemplate],
  ['inventoryDelay', inventoryDelayTemplate],
  ['productionDelay', productionDelayTemplate],
  ['productionTime', productionTimeTemplate],
  ['shipped', shippedTemplate],
  ['delivered', deliveredTemplate],
]);

const Line = ({ relativeSize, durationText }: { relativeSize: number; durationText: string }) => (
  <div style={{ display: 'flex', flexDirection: 'column', minWidth: '60px', flexGrow: relativeSize }}>
    <div style={{ display: 'flex', justifyContent: 'center', fontSize: '0.8em', color: colors.shale }}>
      {durationText}
    </div>
    <div style={{ display: 'flex', alignItems: 'center', flexGrow: 1 }}>
      <div className={lineStyle} />
    </div>
  </div>
);

const TimelineStepElement = ({
  icon,
  hoverText,
  duration,
}: {
  icon: JSX.Element;
  hoverText: string;
  duration?: {
    durationText: string;
    durationMs: number;
  };
}) => (
  <>
    <Tooltip direction="top" contents={hoverText}>
      {icon}
    </Tooltip>
    {duration ? <Line relativeSize={duration.durationMs} durationText={duration.durationText} /> : <></>}
  </>
);

export const Timeline = ({
  accessToken,
  orderCreatedDate,
  earliestDeliveryDate,
  debugLinks,
  timezone,
  shipDateData,
  requestedQuantity,
}: {
  accessToken: string;
  orderCreatedDate: Moment | undefined;
  earliestDeliveryDate: Moment | undefined;
  debugLinks: BranchLinks | null;
  timezone: string | undefined;
  shipDateData: ShipDateResponse | undefined;
  requestedQuantity: number;
}) => {
  const { isLoading: isLoadingProductionTime, data: productionTimeResponse } = useResource<ProductionTimeResponse>(
    accessToken,
    debugLinks?.productionTimeEvaluation?.href,
  );

  const { isLoading: isLoadingInventory, data: inventoryResponse } = useResource<InventoryResponse>(
    accessToken,
    debugLinks?.inventory?.href,
  );

  const isLoading = isLoadingProductionTime || isLoadingInventory;

  if (isLoading) {
    return <Loading fillSpace={false} />;
  }

  if (!earliestDeliveryDate || !shipDateData) {
    return (
      <Card>
        <div className={emptyTimelineCss}>
          <div className={noTimelineTextCss}>No timeline available</div>
        </div>
      </Card>
    );
  }

  const orderCreateDateOrDefault = orderCreatedDate || moment();
  const cutoffDate = moment(shipDateData?.nextCutoffTime);
  const productionTimeStartDate = moment(shipDateData?.expectedProductionStartDateTime);
  const earliestShipDate = moment(shipDateData?.earliestShipDateTime);

  const hasProductionDelay = cutoffDate.diff(productionTimeStartDate, 'minutes');
  const hasInventoryDelay = inventoryResponse
    ? !isInventoryAvailable(inventoryResponse, orderCreateDateOrDefault, requestedQuantity)
    : false;

  const inventoryAvailableDate = inventoryResponse
    ? getInventoryAvailableDate(inventoryResponse, orderCreateDateOrDefault, requestedQuantity)
    : undefined;

  const timelineSteps: TimelineStep[] = [];

  const localCutoffDate = timezone ? cutoffDate.tz(timezone) : cutoffDate;
  const localInventoryAvailableDate =
    inventoryAvailableDate && (timezone ? inventoryAvailableDate.tz(timezone) : inventoryAvailableDate);

  timelineSteps.push({
    type: 'orderCreated',
    startsAt: timezone ? orderCreateDateOrDefault.tz(timezone) : orderCreateDateOrDefault,
  });

  if (hasInventoryDelay) {
    timelineSteps.push({
      type: 'inventoryDelay',
      startsAt: localCutoffDate,
    });
  }

  if (hasProductionDelay) {
    timelineSteps.push({
      type: 'productionDelay',
      startsAt: localInventoryAvailableDate ? localInventoryAvailableDate : localCutoffDate,
    });
  }

  if (productionTimeResponse) {
    timelineSteps.push({
      type: 'productionTime',
      startsAt: timezone ? productionTimeStartDate.tz(timezone) : productionTimeStartDate,
      productionTime: productionTimeResponse,
    });
  }

  timelineSteps.push({
    type: 'shipped',
    startsAt: timezone ? earliestShipDate.tz(timezone) : earliestShipDate,
    earliestShipDate,
    earliestDeliveryDate,
  });

  timelineSteps.push({
    type: 'delivered',
    startsAt: earliestDeliveryDate,
  });

  const timeline = buildTimeline(timelineSteps, timelineTemplates);

  return (
    <Card style={{ width: '100%' }}>
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>{timeline}</div>
    </Card>
  );
};

const buildTimeline = (
  timelineSteps: TimelineStep[],
  timelineTemplates: Map<TimelineStepType, TimelineStepTemplate>,
): JSX.Element[] => {
  return timelineSteps.map((timelineStep, index) => {
    const nextStep = timelineSteps[index + 1];

    // eslint-disable-next-line
    const template = timelineTemplates.get(timelineStep.type)!;

    const durationMs = nextStep?.startsAt.diff(timelineStep.startsAt) ?? 0;
    const durationText = durationDisplayText(durationMs);

    let timelineStepElement;

    switch (timelineStep.type) {
      case 'productionTime': {
        const productionTimeDurationText = getProductionTimeDurationText(timelineStep.productionTime);
        const duration = {
          durationText: productionTimeDurationText,
          durationMs,
        };

        timelineStepElement = (
          <TimelineStepElement
            key={index}
            icon={template.icon}
            hoverText={template.hoverText(timelineStep.startsAt.format(template.dateFormat))}
            duration={duration}
          />
        );
        break;
      }

      case 'delivered': {
        timelineStepElement = (
          <TimelineStepElement
            key={index}
            icon={template.icon}
            hoverText={template.hoverText(timelineStep.startsAt.format(template.dateFormat))}
          />
        );
        break;
      }

      case 'shipped': {
        const startDay = moment(timelineStep.startsAt).startOf('day');
        const nextStepStartDay = moment(nextStep.startsAt).startOf('day');

        const shippedDurationText = deliveryDurationText(startDay, timelineStep.earliestDeliveryDate);

        const duration = {
          durationText: shippedDurationText,
          durationMs: nextStepStartDay.diff(startDay),
        };

        timelineStepElement = (
          <TimelineStepElement
            key={index}
            icon={template.icon}
            hoverText={template.hoverText(timelineStep.startsAt.format(template.dateFormat))}
            duration={duration}
          />
        );
        break;
      }

      case 'orderCreated':
      // fallthrough
      case 'inventoryDelay':
      // fallthrough
      case 'productionDelay': {
        const duration = nextStep ? { durationText, durationMs } : undefined;

        timelineStepElement = (
          <TimelineStepElement
            key={index}
            icon={template.icon}
            hoverText={template.hoverText(timelineStep.startsAt.format(template.dateFormat))}
            duration={duration}
          />
        );
        break;
      }

      // if you hit this error, it means you forgot to implement the display for a timeline step
      default:
        throw Error('Unhandled timeline step');
    }

    return timelineStepElement;
  });
};

const getProductionTimeDurationText = (productionTimeResponse: ProductionTimeResponse) => {
  const productionTime = productionTimeResponse.productionTime;

  const hourInMs = 3_600_000;
  const dayInMs = 86_400_000;

  const productionTimeDurationText =
    productionTime?.unit === 'HH'
      ? durationDisplayText(productionTime.time * hourInMs, 'h')
      : durationDisplayText(productionTime.time * dayInMs, 'd');

  return productionTimeDurationText;
};

const deliveryDurationText = (startDate: Moment, endDate: Moment) => {
  const durationMs = endDate.diff(startDate);
  return durationDisplayText(durationMs, 'd');
};

const durationDisplayText = (durationMs: number, unit: 'd' | 'h' | undefined = undefined) => {
  return humanizeDuration(durationMs, {
    round: true,
    largest: 1,
    units: unit ? [unit] : ['d', 'h'],
  });
};
