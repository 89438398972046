import React from 'react';
import { colors } from '@cimpress/react-components';
import { css } from '@emotion/css';
import { Product } from '../services/introduction';

const imageCss = ({ height, width }: { height: number; width: number }) => css`
  height: ${height}px;
  width: ${width}px;
  border: solid 1px ${colors.alloy};
`;

const noImageCss = ({ height, width }: { height: number; width: number }) => css`
  display: flex;
  justify-content: center;
  align-items: center;
  height: ${height}px;
  width: ${width}px;
  background-color: ${colors.horizon};
  color: ${colors.slate};
  font-size: 1rem;
  border: solid 1px ${colors.alloy};
`;

type ProductImageProps = {
  product: Product | undefined;
  height?: number;
  width?: number;
};

export const ProductImage = ({ product, height = 130, width = 130 }: ProductImageProps) => {
  if (product?.sceneUrl) {
    return <img className={imageCss({ height, width })} src={product?.sceneUrl} alt={product.description} />;
  }
  return <div className={noImageCss({ height, width })}>No image available</div>;
};
