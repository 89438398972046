import React, { useEffect, useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { Button, Snackbar } from '@cimpress/react-components';

export const StepUpNotificationPopup = props => {
  const { title, remainingTime, reminderBeforeMin, localStorageKey, openStepUpAuthModal, delay } = props;
  const [showStepupAuthPopup, setshowStepupAuthPopup] = useState(false);

  useEffect(
    () => {
      let timeout;
      if (remainingTime && remainingTime > 0) {
        const stepupReminder = localStorage.getItem(localStorageKey);
        if (!stepupReminder) {
          timeout = setTimeout(() => {
            setshowStepupAuthPopup(true);
            clearTimeout(timeout);
          }, remainingTime + 1000 - reminderBeforeMin * 60000);
        }
      }
      return () => timeout && clearTimeout(timeout);
    },
    [localStorageKey, remainingTime, reminderBeforeMin]
  );

  const continueHandler = useCallback(
    () => {
      setshowStepupAuthPopup(false);
      openStepUpAuthModal(true);
    },
    [setshowStepupAuthPopup, openStepUpAuthModal]
  );

  const hideSnackbarHandler = useCallback(
    () => {
      localStorage.setItem(localStorageKey, 'true');
      setshowStepupAuthPopup(false);
    },
    [localStorageKey]
  );

  return (
    <Snackbar show={showStepupAuthPopup} status={'info'} delay={delay} onHideSnackbar={hideSnackbarHandler}>
      <p>{title}</p>
      <Button variant="default" onClick={continueHandler} style={{ marginTop: '10px' }}>
        Continue
      </Button>
    </Snackbar>
  );
};

StepUpNotificationPopup.propTypes = {
  delay: PropTypes.number,
  title: PropTypes.string.isRequired,
  remainingTime: PropTypes.number.isRequired,
  reminderBeforeMin: PropTypes.number.isRequired,
  localStorageKey: PropTypes.string.isRequired,
  openStepUpAuthModal: PropTypes.func.isRequired,
};
