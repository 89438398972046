import React from 'react';
import { colors } from '@cimpress/react-components';
import { css } from '@emotion/css';
import accounting from 'accounting';

import { Price } from '../services/itemDeliveryPossibilities';

type PriceTextProps = {
  price?: Price;
};

const priceCss = css`
  display: flex;
  font-size: 1.5rem;
  margin-bottom: 5px;
`;

const priceNotAvailableCss = css`
  font-weight: bold;
  color: ${colors.cobalt.base};
`;

const productPriceTextCss = css`
  font-weight: bold;
  margin-right: 5px;
`;

const shippingPriceTextCss = css`
  color: ${colors.cobalt.base};
`;

export const PriceText = ({ price }: PriceTextProps) => {
  if (!price || !price.product || !price.shipping) {
    return (
      <div className={priceCss}>
        <div className={priceNotAvailableCss}>Price information unavailable</div>
      </div>
    );
  }

  const productPrice = price.product;
  const productPriceAmount = accounting.formatMoney(productPrice.price);
  const productPriceCurrency = productPrice.currency;

  const shippingPrice = price.shipping;
  const shippingPriceAmount = accounting.formatMoney(shippingPrice.price);
  const shippingPriceCurrency = shippingPrice.currency;

  return (
    <div className={priceCss}>
      <div className={productPriceTextCss}>
        {productPriceAmount} ({productPriceCurrency})
      </div>
      <div className={shippingPriceTextCss}>
        +{shippingPriceAmount} ({shippingPriceCurrency}) shipping
      </div>
    </div>
  );
};
