import React from 'react';
import { css } from '@emotion/css';
import { Robot } from '@cimpress/react-components';

const unauthorizedCss = css`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const Unauthorized = () => {
  return (
    <div className={unauthorizedCss}>
      <Robot status="danger" size="lg" />
      <h1>Unauthorized</h1>
    </div>
  );
};

export default Unauthorized;
