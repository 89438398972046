import React from 'react';
import { QueryClient, QueryClientProvider } from 'react-query';
import DeliveryPossibilities, { DeliveryPossibilitiesProps } from './DeliveryPossibilities';
import Unauthorized from './Unauthorized';
import { GlobalStyles } from '@cimpress/react-components';

export type DeliveryPossibilitiesComponentProps = {
  accessToken: string | undefined;
} & DeliveryPossibilitiesProps;

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});

const DeliveryPossibilitiesComponent = (props: DeliveryPossibilitiesComponentProps) => (
  <div>
    <GlobalStyles />
    <QueryClientProvider client={queryClient} contextSharing={true}>
      {props.accessToken ? <DeliveryPossibilities {...props} /> : <Unauthorized />}
    </QueryClientProvider>
  </div>
);

export default DeliveryPossibilitiesComponent;
