import React, { useState } from 'react';
import { css } from '@emotion/css';
import orderBy from 'lodash/orderBy';
import moment, { Moment } from 'moment';
import { DebugBranch } from '../services/itemDeliveryPossibilities';
import { DeliveryPossibilityCard } from './DeliveryPossibilityCard';
import { UseDebugBranchesInput, useDebugBranches } from '../hooks/useDebugBranches';
import Loading from './Loading';

type AddressType = 'residential' | 'commercial' | 'logisticsHub' | 'pickupPoint';

export type DeliveryPossibilitiesProps = {
  accessToken: string;
  productConfigurationUrl: string;
  merchantId: string;
  quantity: number;
  country?: string | undefined;
  postalCode?: string | undefined;
  pickupPointUrl?: string | undefined;
  addressType?: AddressType | undefined;
  isPOBox?: boolean | undefined;
  usePacks?: boolean | undefined;
  minutesToOrderSubmittal?: number | undefined;
  requestDateTime?: Moment | Date | string | undefined;
  fulfillmentCapabilities?: string[] | undefined;
  carrierServices?: string[] | undefined;
  carrierServiceCapabilities?: string[] | undefined;
  dispatchCountry?: string | undefined;
  dispatchPostalCode?: string | undefined;
  currencyCode?: string | undefined;
  timezone?: string | undefined;
  ignoreInTransitInventory?: boolean;
  onSuccess?: (data: DebugBranch[]) => void;
};

const possibilityCardCss = css`
  margin-bottom: 10px;
`;

const possibilityCardsCss = css`
  max-height: 650px;
  overflow-y: auto;
`;

const sortBranches = (branches: DebugBranch[] | undefined): DebugBranch[] => {
  return orderBy(branches, branch => {
    const firstDeliverableDate = branch.deliverableDates?.[0];
    const date = firstDeliverableDate?.date.unix() ?? Infinity;
    const productPrice = firstDeliverableDate?.price?.product?.price;
    const shippingPrice = firstDeliverableDate?.price?.shipping?.price;
    const totalPrice = (productPrice ?? 0) + (shippingPrice ?? 0) || Infinity;
    return [date, totalPrice];
  });
};

const DeliveryPossibilities = ({
  accessToken,
  productConfigurationUrl,
  merchantId,
  quantity,
  country,
  postalCode,
  pickupPointUrl,
  addressType,
  isPOBox,
  minutesToOrderSubmittal,
  requestDateTime,
  fulfillmentCapabilities,
  carrierServices,
  carrierServiceCapabilities,
  currencyCode,
  timezone,
  ignoreInTransitInventory,
  onSuccess,
}: DeliveryPossibilitiesProps) => {
  const [sortedBranches, setSortedBranches] = useState<DebugBranch[]>([]);

  if (typeof requestDateTime === 'string' || Object.prototype.toString.call(requestDateTime) === '[object Date]') {
    requestDateTime = moment(requestDateTime);
  }

  const debugBranchesRequest: UseDebugBranchesInput = {
    accessToken,
    productConfigurationUrl,
    merchantId,
    quantity,
    country,
    postalCode,
    pickupPointUrl,
    isPOBox,
    addressType,
    minutesToOrderSubmittal,
    requestDateTime: requestDateTime as Moment | undefined,
    fulfillmentCapabilities,
    carrierServices,
    carrierServiceCapabilities,
    currencyCode,
    ignoreInTransitInventory,
    onSuccess:
      onSuccess &&
      ((branches: DebugBranch[]) => {
        const sortedBranches = sortBranches(branches);
        onSuccess(sortedBranches);
        setSortedBranches(sortedBranches);
      }),
  };
  const { isLoading: isLoadingBranches } = useDebugBranches(debugBranchesRequest);

  if (isLoadingBranches) {
    return <Loading />;
  }

  const cards = sortedBranches?.map((branch: DebugBranch, index: number) => {
    return (
      <DeliveryPossibilityCard
        className={possibilityCardCss}
        key={index}
        accessToken={accessToken}
        orderCreatedDate={requestDateTime as Moment}
        timezone={timezone}
        branch={branch}
        request={debugBranchesRequest}
      />
    );
  });

  return <div className={possibilityCardsCss}>{cards}</div>;
};

export default DeliveryPossibilities;
